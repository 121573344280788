:root {
  --dsa-black: #201d1e;
  --dsa-dark-gray: #3b3838;
  --dsa-gray: #605c5c;
  --dsa-light-gray: #8c8989;
  --dsa-light-light-gray: #c1c0bf;
  --dsa-red: #ec1f27;
  --dsa-red-tint1: #f04c53;
  --dsa-red-tint2: #f4797e;
  --dsa-red-tint3: #f7a5a9;
  --dsa-red-tint4: #fbd2d4;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  color: var(--dsa-dark-gray);
  box-shadow: 0 25px 50px -25px #00000080;
}

a, .dark-red {
  color: var(--dsa-red);
}

.bg-dark-red {
  background-color: var(--dsa-red);
}

.b--dark-red {
  border-color: var(--dsa-red);
}

.dark-gray {
  color: var(--dsa-dark-gray);
}

.tracked-cozy {
  letter-spacing: -.025em;
}

.vr {
  width: 0;
  height: 1em;
  border-style: solid;
  border-width: 1px;
  margin: 0 1em;
}

.site-wrapper {
  min-height: 100%;
  grid-template-rows: auto 1fr auto;
  display: grid;
}

.masthead {
  background-color: var(--dsa-red);
  padding: 2.5% 5% 10%;
  position: relative;
  overflow-x: hidden;
}

.masthead > .scrim {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .3;
  background-image: url("shuksan.3c5c6850.jpg");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.logo {
  width: 10rem;
}

.section-wrap {
  flex-wrap: wrap;
  gap: 2rem;
  display: flex;
}

.section-left, .section-right {
  min-width: 20em;
  flex-grow: 1;
  flex-basis: calc(50% - 1rem);
}

.gap-\.5 {
  gap: .5rem;
}

.event.svelte-1cp9h6o.svelte-1cp9h6o {
  margin-bottom: .5rem;
}

.subdued.event.svelte-1cp9h6o.svelte-1cp9h6o {
  opacity: .75;
  margin-bottom: .25rem;
}

.subdued.svelte-1cp9h6o .time.svelte-1cp9h6o {
  opacity: .75;
  display: inline;
}

details.svelte-1cp9h6o > summary.svelte-1cp9h6o {
  cursor: pointer;
  list-style-type: none;
  transition: all .3s;
}

details.svelte-1cp9h6o > summary.svelte-1cp9h6o::-webkit-details-marker {
  display: none;
}

details.svelte-1cp9h6o > summary.svelte-1cp9h6o:before {
  content: "▶";
  opacity: .5;
  padding-right: .25em;
}

details[open].svelte-1cp9h6o > summary.svelte-1cp9h6o:before {
  content: "▼";
}

details[open].svelte-1cp9h6o.svelte-1cp9h6o {
  margin-bottom: 2rem;
}

details[open].svelte-1cp9h6o summary.svelte-1cp9h6o {
  margin-bottom: .5rem;
}

/*# sourceMappingURL=index.73f20772.css.map */
