:root {
  --dsa-black: #201D1E;
  --dsa-dark-gray: #3B3838;
  --dsa-gray: #605C5C;
  --dsa-light-gray: #8C8989;
  --dsa-light-light-gray: #C1C0BF;
  --dsa-red: #EC1F27;
  --dsa-red-tint1: #F04C53;
  --dsa-red-tint2: #F4797E;
  --dsa-red-tint3: #F7A5A9;
  --dsa-red-tint4: #FBD2D4;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  color: var(--dsa-dark-gray);
  box-shadow: 0px 25px 50px -25px rgba(0,0,0,0.50);
}

a {
  color: var(--dsa-red);
}

/* Tachyons overrides */

.dark-red { color: var(--dsa-red); }
.bg-dark-red { background-color: var(--dsa-red); }
.b--dark-red { border-color: var(--dsa-red); }

.dark-gray { color: var(--dsa-dark-gray); }

/* Tachyons extensions */

.tracked-cozy {
  letter-spacing: -.025em;
}

/* Custom */

.vr {
  border-width: 1px;
  border-style: solid;
  width: 0px;
  height: 1em;
  margin: 0em 1em;
}

.site-wrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.masthead {
  background-color: var(--dsa-red);
  position: relative;
  padding: 2.5% 5% 10%;
  overflow-x: hidden;
}

.masthead > .scrim {
  /* background-image: url(https://images.unsplash.com/photo-1591910029206-a2379462a30b?auto=format&fit=crop&w=1920&q=80&sat=-100&blend-color=red&blend-mode=multiply); */
  /* locally saved version of above url */
  background-image: url(img/shuksan.jpg);
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-size: cover;
  opacity: .3;
}

.logo {
  width: 10rem;
}

.section-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.section-left,
.section-right {
  flex-basis: calc(50% - 1rem);
  flex-grow: 1;
  min-width: 20em;
}

.gap-\.5 {
  gap: .5rem;
}
